<template>
  <header class="header" :class="{'header-active':active}">
    <div class="header__container container">
      <div class="header__nav-wrapper">
        <div class="header__logo">
          <router-link to="/">
            <svg width="88" height="17" viewBox="0 0 88 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.084 7.286V4.452H0v12.346h3.084v-6.072l6.374-3.845-1.44-2.429-4.934 2.834zM18.3 13.762c-1.646 0-3.085-1.417-3.085-3.238 0-1.822 1.44-3.239 3.084-3.239 1.645 0 3.084 1.417 3.084 3.239.206 1.821-1.233 3.238-3.084 3.238zm0-9.31c-3.29 0-6.17 2.833-6.17 6.274 0 3.44 2.674 6.274 6.17 6.274 3.289 0 6.167-2.834 6.167-6.274 0-3.643-2.672-6.274-6.168-6.274zM34.13 13.762c-1.644 0-3.084-1.417-3.084-3.238 0-1.822 1.44-3.238 3.085-3.238 1.644 0 3.084 1.416 3.084 3.238.205 1.821-1.234 3.238-3.084 3.238zm0-9.31c-3.29 0-6.168 2.834-6.168 6.274S30.635 17 34.131 17c3.495 0 6.168-2.833 6.168-6.274 0-3.643-2.673-6.274-6.168-6.274zM49.963 13.762c-1.645 0-3.084-1.417-3.084-3.238 0-1.822 1.439-3.238 3.084-3.238s3.084 1.416 3.084 3.238c0 1.821-1.234 3.238-3.084 3.238zm0-9.31c-1.234 0-2.262.405-3.084.81V0H44v10.524c0 3.44 2.673 6.274 6.168 6.274 3.29 0 6.169-2.834 6.169-6.274-.206-3.44-2.879-6.072-6.374-6.072zM62.917 9.31c.41-1.417 1.439-2.024 2.878-2.024 1.44 0 2.673.607 2.879 2.023h-5.757zM66 4.451c-1.85 0-3.496.607-4.524 1.822-1.028 1.214-1.645 2.63-1.645 4.452 0 1.62.617 3.238 1.645 4.453C62.711 16.393 64.356 17 66.001 17c1.85 0 3.495-.607 4.523-1.619l.206-.202-2.056-2.024-.206.202c-.617.607-1.44.81-2.262.81-1.85 0-2.878-1.215-3.084-2.024h8.841v-.203-1.416c0-3.643-2.467-6.072-5.962-6.072zM78.953 9.31c.411-1.417 1.44-2.024 2.879-2.024s2.673.607 2.878 2.023h-5.757zm3.084-4.858c-1.85 0-3.495.607-4.523 1.822-1.028 1.214-1.645 2.63-1.645 4.452 0 1.62.617 3.238 1.645 4.453C78.748 16.393 80.393 17 82.037 17c1.85 0 3.496-.607 4.524-1.619l.205-.202-2.056-2.024-.205.202c-.617.607-1.44.81-2.262.81-1.85 0-2.879-1.215-3.084-2.024H88v-.203-1.416c0-3.643-2.467-6.072-5.963-6.072z" fill="#000"/></svg>
          </router-link>
        </div>
      </div>
      <div class="header__auth-lang-wrapper">
        <a target="_blank" href="https://app.roobee.io/sign_in" class="header__login">
          <span v-t="'header.login'" />
        </a>
        <a target="_blank" href="https://app.roobee.io/sign_up" class="header__sign-up">
          <span v-t="'header.sign-up'" />
        </a>
<!--        <langs class="desktop" />-->
      </div>
    </div>
    <div class="header__menu">
      <div class="header__menu-bottom">
        <a target="_blank" href="https://app.roobee.io/sign_in" class="header__login">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.91 10H2.918a.417.417 0 010-.833h8.994L9.289 6.545a.417.417 0 01.589-.59l3.333 3.334a.417.417 0 010 .589l-3.333 3.333a.417.417 0 01-.59-.589L11.912 10zM8.75 3.333a.417.417 0 110-.833h6.667c1.15 0 2.083.933 2.083 2.083v10.834c0 1.15-.933 2.083-2.083 2.083H8.75a.417.417 0 110-.833h6.667c.69 0 1.25-.56 1.25-1.25V4.583c0-.69-.56-1.25-1.25-1.25H8.75z" fill="#181818" stroke="#181818" stroke-width=".3"/></svg>
          <span v-t="'header.login'" />
        </a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",

  components: {
    // Langs: () => import('@/components/Langs')
  },

  data() {
    return {
      active: false,
    }
  },

  created() {
    window.addEventListener('scroll',() => {
      this.active = window.scrollY !== 0;
    })
  },

  computed: {},

  methods: {}
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/header';
</style>
